<template>
  <v-container fluid>
    <v-row dense>
      <v-col>
        <h1>Sales Bonus for {{ monthName}} {{ year }}</h1>
      </v-col>
      <v-col class="text-right">
        <v-btn @click="exportToExcel()" small>Export to Excel</v-btn>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <p>Status key: A = Application, I = Draft Invoice, S = On Sage, P = Paid, PF = ProForma</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-3">
        <v-date-picker
          v-model="date"
          type="month"
        ></v-date-picker>
      </v-col>
      <v-col class="col-9">
        <v-tabs v-model="tab">
          <v-tab
            v-for="(person, index) in salespeople"
            :key="`p_${index}`">
            {{ person.initials }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item
            v-for="(person, index) in salespeople"
            :key="`sp_${index}`">
            <v-simple-table>
              <thead>
                <tr>
                  <th>Number</th>
                  <th>Status</th>
                  <th>Date</th>
                  <th>Created date</th>
                  <th>Customer</th>
                  <th>Address</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(invoice, index) in person.invoices" :key="`i_${index}`">
                  <td>{{ invoice.clone_id }}-{{ invoice.clone_count }}-{{ invoice.quoted_by_initials }}</td>
                  <td>{{ invoice.statusLetter }}</td>
                  <td>{{ invoice.date | tinyDate }}</td>
                  <td>{{ invoice.created_date | tinyDate }}</td>
                  <td>{{ invoice.customer }}</td>
                  <td>{{ invoice.address }}</td>
                  <td>{{ invoice.value | priceInPounds | currency }}</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th colspan="6">&nbsp;</th>
                  <th>{{ person.total | priceInPounds | currency }}</th>
                </tr>
              </tfoot>
            </v-simple-table>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from '../../axios';

export default {
  name: 'SalesCommission',
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  data() {
    return {
      salespeople: [],
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      monthName: '',
      year: '',
      tab: null,
      attrs: {
        boilerplate: false,
        elevation: 2,
      },
    };
  },
  watch: {
    date() {
      this.getCommission();
    }
  },
  methods: {
    getCommission() {
      const postData = {};
      postData.date = this.date;
      axios.post(`/invoices/getCommission.json?token=${this.token}`, postData)
        .then((response) => {
          this.salespeople = response.data.salespeople;
          this.monthName = response.data.month;
          this.year = response.data.year;
        });
    },
    exportToExcel() {
      const postData = {};
      postData.date = this.date;
      axios.post(`/spreadsheets/salesCommission.json?token=${this.token}`, postData, {responseType:'blob'})
        .then((response) => {
          const href = URL.createObjectURL(response.data);
          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download', `sales_bonus_for_month_${this.monthName}-${this.year}.xlsx`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        });
    },
  },
  mounted() {
    this.getCommission();
  },
};
</script>
  